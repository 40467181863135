<template>
  <div class="live_manage_content">
    <div class="app-container outer">
      <div v-if="JSON.stringify(liveControlData) != '{}'">
        <div class="live_manage_title">
          直播管理
        </div>
        <div class="live_content">
          <div class="live_img">
            <el-image
              style="width: 100%; height: 100%"
              :src="liveControlData.coverImageId"
              fit="contain"
            />
          </div>
          <div class="live_msg">
            <div class="name">
              {{ liveControlData.title }}
            </div>
            <div
              class="time"
            >
              开播时间：{{ dayFormat(liveControlData.liveStartTime) }} - {{ dayFormat(liveControlData.liveEndTime) }}
            </div>
            <div class="anchor">
              主播：{{ liveControlData.actorName }}
            </div>
            <div class="anchor_number">
              主播账号：{{ liveControlData.actorId }}
            </div>
          </div>
        </div>
        <div
          v-if="liveControlData.remainingTime > 0"
          class="live_prediction"
        >
          直播预告:
          <CountDown :remaining-time="liveControlData.remainingTime" />
        </div>
      </div>

      <div class="live_tabs">
        <div
          v-if="activeName == 'data'"
          class="more"
          @click="showMoreData"
        >
          <i class="el-icon-more"></i>
        </div>
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            label="直播商品"
            name="goods"
          >
            <GoodsManage
              v-if="activeName == 'goods'"
              :live-id="liveId"
              :activity-id="id"
            />
          </el-tab-pane>
          <el-tab-pane
            label="优惠券"
            name="coupon"
          >
            <Coupon
              v-if="activeName == 'coupon'"
              :activity-id="id"
            />
          </el-tab-pane>
          <el-tab-pane
            label="直播数据"
            name="data"
          >
            <LiveData
              v-if="activeName == 'data'"
              :activity-id="id"
            />
          </el-tab-pane>
          <el-tab-pane
            label="设置"
            name="set"
          >
            <LiveSet
              v-if="activeName == 'set'"
              :activity-id="id"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog
      title="数据"
      :visible.sync="showMore"
      :before-close="handleClose"
    >
      <div
        v-show="JSON.stringify(allData) !== '{}'"
        class="data_content"
      >
        <div class="view_data">
          <div class="title">
            观看数据
          </div>
          <div class="content">
            <span>观看人数</span> {{ allData.liveWatchPerSonNum }}
          </div>
          <div class="content">
            <span>观看次数</span> {{ allData.liveWatchNum }}
          </div>
        </div>
        <div class="view_data">
          <div class="title">
            商品数据
          </div>
          <div class="content">
            <span>累计推送次数</span> {{ allData.pushNum }}
          </div>
          <div class="content">
            <span>商品点击人数</span> {{ allData.goodsClickPerconNum }}
          </div>
          <div class="content">
            <span>商品点击次数</span> {{ allData.goodsClickNum }}
          </div>
        </div>
        <div class="view_data">
          <div class="title">
            互动数据
          </div>
          <div class="content">
            <span>点赞人数</span> {{ allData.liveLikePersonNum }}
          </div>
          <div class="content">
            <span>点赞次数</span> {{ allData.liveLikeNum }}
          </div>
          <div class="content">
            <span>评论人数</span> {{ allData.commentPersonNum }}
          </div>
          <div class="content">
            <span>评论次数</span> {{ allData.commentNum }}
          </div>
          <div class="content">
            <span>分享人数</span> {{ allData.sharePersonNum }}
          </div>
          <div class="content">
            <span>分享次数</span> {{ allData.shareNum }}
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showMore = false">取 消</el-button>
        <el-button
          type="primary"
          @click="showMore = false"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 直播控制台页面
 * 用途：
 */
import dayjs from 'dayjs';
import GoodsManage from './components/goodsManage';
import Coupon from './components/coupon';
import LiveData from './components/data';
import LiveSet from './components/set';
import CountDown from '@/components/CountDown';

export default {
  name: 'control',
  components: {
    GoodsManage,
    Coupon,
    LiveData,
    LiveSet,
    CountDown,
  },
  props: {

  },
  data() {
    return {
      showMore: false,
      allData: {},
      activeName: 'goods',
      liveControlData: {},
      id: Number(this.$route.query.id), // 直播活动id
      liveId: Number(this.$route.query.liveId), // 主播id
    };
  },

  mounted() {
    this.getLiveManageMsg();
    this.getData();
  },
  methods: {

    handleClick(tab, event) {
      console.log(tab, event);
    },

    getLiveManageMsg() {
      this.$axios(this.$api.live.live_control_msg, {
        params: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.liveControlData = {
            ...res.data,
          };
        }
      });
    },

    dayFormat(val) {
      return val ? dayjs(Number(val)).format('YYYY-MM-DD HH:mm:ss') : '';
    },
    showMoreData() {
      this.showMore = true;
    },
    handleClose() {
      this.showMore = false;
    },
    getData() {
      this.$axios(this.$api.live.live_data_statistics, {
        params: {
          liveid: this.id,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.allData = res.data;
        }
      });
    },
  },

};
</script>
<style lang="scss" scoped>
.live_manage_content {
  .live_manage_title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .live_content {
    display: flex;
    .live_img {
      width: 300px;
      height: 180px;
      text-align: center;
      background: #999;
      line-height: 180px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .live_msg {
      margin-left: 20px;
      .name {
        font-size: 16px;
      }
      div {
        margin-top: 20px;
      }
    }
  }
  .live_prediction {
    background: rgba(242, 242, 242, 1);
    font-size: 18px;
    font-weight: 900;
    margin: 20px 0;
    padding: 30px;
    span {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .live_tabs {
    position: relative;
    .more {
      position: absolute;
      right: 12px;
      top: 12px;
      transform: rotate(90deg);
      cursor: pointer;
      font-size: 20px;
      z-index: 100;
    }
  }
}
.data_content {
        display: flex;
        justify-content: space-between;
        .view_data {
          text-align: center;
            width: 30%;
            .title {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 20px;
            }
            .content {
                margin-top: 10px;
            }
            span {
                display: inline-block;
                width: 30%;
            }
        }

    }

</style>

<style lang="scss">
.goods_message {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  .left {
    margin: 10px 15px;
    img {
      width: 80px;
      // height: 80px;
    }
  }
  .right {
    .title {
      margin: 10px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .price {
      color: red;
    }
  }
}
</style>
