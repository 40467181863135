var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "live_data_content" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "box-wrap" },
        [
          _c("el-card", { staticClass: "box-card" }, [
            _c("div", [
              _vm._v("活动交易总额（元）：" + _vm._s(_vm.transactionValue)),
            ]),
          ]),
          _c("el-card", { staticClass: "box-card" }, [
            _c("div", [_vm._v("支付订单数（笔）：" + _vm._s(_vm.subOrderNum))]),
          ]),
          _c("el-card", { staticClass: "box-card" }, [
            _c("div", [_vm._v("活动交易商品数：" + _vm._s(_vm.goodsNum))]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("lineEchart", {
            attrs: {
              "sale-chart-date": _vm.saleChartDate,
              "sale-chart-count": _vm.saleChartCount,
              "sale-chart-money": _vm.saleChartMoney,
            },
          }),
          _c("lineMoneyEchart", {
            attrs: {
              "sale-chart-date": _vm.saleChartDate,
              "sale-chart-count": _vm.saleChartCount,
              "sale-chart-money": _vm.saleChartMoney,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }