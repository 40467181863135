var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "live_manage_content" },
    [
      _c(
        "search-panel",
        {
          attrs: { flex: "", "show-btn": "" },
          on: { getList: _vm.getListChange },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  placeholder: "请输入商品名称",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _vm.goods_title,
                  callback: function ($$v) {
                    _vm.goods_title = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "goods_title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "button-right", size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.addGoods("add")
                },
              },
              slot: "button-right",
            },
            [_vm._v(" 添加商品 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "button-right", size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  _vm.showBatchModel = true
                },
              },
              slot: "button-right",
            },
            [_vm._v(" 批量添加 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "element-loading-text": "拼命加载中",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "序号", width: "110" },
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsId", label: "商品ID", width: "110" },
          }),
          _c("el-table-column", {
            attrs: { label: "商品信息", "min-width": "210" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { staticClass: "goods_message" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("img", { attrs: { src: row.image } }),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(" " + _vm._s(row.goodsName) + " "),
                        ]),
                        _c(
                          "div",
                          [
                            row.promType !== 0
                              ? _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      size: "mini",
                                      effect: "dark",
                                      type: "danger",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ACTIVE_TYPE_MAP[row.promType]
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "price" }, [
                          row.promType === 0
                            ? _c("span", [
                                row.minPrice === row.maxPrice
                                  ? _c("span", [
                                      _vm._v("￥" + _vm._s(row.minPrice)),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "￥" +
                                          _vm._s(row.minPrice) +
                                          " - ￥" +
                                          _vm._s(row.maxPrice)
                                      ),
                                    ]),
                              ])
                            : _c("span", [
                                _vm._v("￥" + _vm._s(row.lowPromPrice)),
                              ]),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "remark",
              label: "商品备注信息",
              "min-width": "210",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "clickCount", label: "点击次数", width: "110" },
          }),
          _c(
            "el-table-column",
            {
              attrs: { width: "130px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("el-input-number", {
                        staticClass: "w100",
                        attrs: {
                          min: 0,
                          max: 999999,
                          placeholder: "排序",
                          size: "mini",
                          controls: false,
                          precision: 0,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.setSort(row)
                          },
                        },
                        model: {
                          value: row.goodsWeight,
                          callback: function ($$v) {
                            _vm.$set(row, "goodsWeight", $$v)
                          },
                          expression: "row.goodsWeight",
                        },
                      }),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _vm._v(" 排序 "),
                _c("span", { staticClass: "orange f12" }, [_vm._v("大数在前")]),
              ]),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { label: "操作", width: "220", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.toOperate(scope.row, "push")
                          },
                        },
                      },
                      [_vm._v(" 推送 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", plain: "", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.toOperate(scope.row, "delete")
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.toOperate(scope.row, "top")
                          },
                        },
                      },
                      [_vm._v(" 置顶 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination-panel", {
        attrs: { page: _vm.pagination, load: _vm.loading, "show-select": "" },
        on: { getList: _vm.getList },
      }),
      _vm.selectDialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "可选择商品",
                visible: _vm.selectDialogVisible,
                "before-close": () => (_vm.selectDialogVisible = false),
                width: "1200px",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.selectDialogVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "describe" }, [
                _c(
                  "div",
                  { staticClass: "search" },
                  [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: "请搜索商品名称",
                          size: "mini",
                          clearable: "",
                        },
                        model: {
                          value: _vm.goodsMsg,
                          callback: function ($$v) {
                            _vm.goodsMsg = $$v
                          },
                          expression: "goodsMsg",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: { slot: "append", icon: "el-icon-search" },
                          on: { click: _vm.searchList },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "select_goods" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedList && _vm.selectedList.length >= 0
                                ? `已选择${
                                    _vm.selectedList.length
                                  }件商品，还可选择${
                                    200 - _vm.selectedList.length
                                  }件商品`
                                : ""
                            ) +
                            " "
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { marginLeft: "20px" },
                            attrs: {
                              disabled:
                                _vm.selectedList &&
                                _vm.selectedList.length <= 0,
                              type: "primary",
                              size: "mini",
                            },
                            on: { click: _vm.onSureAdd },
                          },
                          [_vm._v(" 导入商品 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.selectTableData,
                    border: "",
                    "header-cell-style": {
                      background: "#D9DDE1",
                      color: "#0D0202",
                      "font-weight": 400,
                    },
                    "row-key": _vm.getRowKey,
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      align: "center",
                      "reserve-selection": true,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品信息", "min-width": "210" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "goods_message" }, [
                                _c("div", { staticClass: "left" }, [
                                  _c("img", {
                                    attrs: { src: scope.row.coverImgUrl },
                                  }),
                                ]),
                                _c("div", { staticClass: "right" }, [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(" " + _vm._s(scope.row.name) + " "),
                                  ]),
                                  _c("div", { staticClass: "id" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.minSellPrice) +
                                        " - " +
                                        _vm._s(scope.row.maxSellPrice) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1409244684
                    ),
                  }),
                ],
                1
              ),
              _c("pagination-panel", {
                attrs: {
                  page: _vm.selectPagination,
                  load: _vm.selectLoading,
                  "show-select": "",
                },
                on: { getList: _vm.getModalList },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量添加商品",
            visible: _vm.showBatchModel,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showBatchModel = $event
            },
          },
        },
        [
          _c("div", [
            _c("p", { staticStyle: { "line-height": "22px" } }, [
              _vm._v(" 请输入商品ID "),
            ]),
            _c(
              "div",
              [
                _c("el-input", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "textarea",
                    rows: 8,
                    placeholder: "请输入商品ID，多个商品ID之间用英文逗号隔开",
                  },
                  on: {
                    input: function ($event) {
                      _vm.bathGoodsId = _vm.bathGoodsId
                        .replace(/，/g, ",")
                        .replace(/[^\d,]/g, "")
                        .replace(/(,)\1/g, ",")
                        .replace(/^,/, "")
                    },
                    blur: function ($event) {
                      _vm.bathGoodsId = Array.from(
                        new Set(_vm.bathGoodsId.replace(/,$/, "").split(","))
                      ).join(",")
                    },
                  },
                  model: {
                    value: _vm.bathGoodsId,
                    callback: function ($$v) {
                      _vm.bathGoodsId =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "bathGoodsId",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticStyle: { "margin-top": "10px" } }, [
              _vm._v(" 请在上方输入商品ID，多个商品ID之间用英文逗号隔开 "),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  color: "red",
                  "margin-top": "10px",
                  "line-height": "20px",
                },
              },
              [_vm._v(" " + _vm._s(_vm.bathGoodsText) + " ")]
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.batchAddGoods },
                },
                [_vm._v("确认添加")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }