// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.goods_message {\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -ms-flex-pack: distribute;\n      justify-content: space-around;\n}\n.goods_message .left {\n  margin: 10px 15px;\n}\n.goods_message .left img {\n  width: 80px;\n}\n.goods_message .right .title {\n  margin: 10px 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  line-clamp: 2;\n  -webkit-box-orient: vertical;\n}\n.goods_message .right .price {\n  color: red;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
