// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.blessing-bag {\n  margin: 0 20px 20px 20px;\n  padding: 0 20px 20px 20px;\n}\n.blessing-bag .el-input-number {\n  width: 110px;\n}\n.blessing-bag .link {\n  text-decoration: underline;\n  color: #1890ff;\n  cursor: pointer;\n}\n.blessing-bag .category_title_img {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding-bottom: 20px;\n}\n.blessing-bag .title {\n  font-weight: 700;\n  font-size: 16px;\n  margin-right: 20px;\n  margin-bottom: 20px;\n}\n.blessing-bag .table-switch {\n  margin-left: 10px;\n}\n.blessing-bag .table-button {\n  width: 100%;\n  border: 1px solid #dfe6ec;\n  border-top: none;\n  height: 48px;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
