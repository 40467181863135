<template>
  <div class="live_set_content">
    <div>
      <ul>
        <li>
          <div class="left">
            <div class="title">
              直播间禁言
            </div>
            <div class="intro">
              开启后，所有观众无法在直播间发表评论
            </div>
          </div>
          <div class="right">
            <el-switch
              v-model="shutUp"
              @change="changeForbid"
            />
          </div>
        </li>
        <li>
          <div class="left">
            <div class="title">
              直播回放
            </div>
            <div class="intro">
              开启后，观众可在直播结束页面查看本场直播回放
            </div>
          </div>
          <div class="right">
            <el-switch
              v-model="openRecording"
              @change="changePlay"
            />
          </div>
        </li>
        <li>
          <div class="live_set_content_img">
            <div class="left ">
              <div class="title">
                直播间贴图
              </div>
              <div class="intro">
                宽度固定135pt
              </div>
            </div>
            <CutImgUp
              :url="imageUrlList"
              :auto-crop-width="750"
              :auto-crop-height="750"
              :max-num="1"
              @change="onSuccess"
            />
          </div>
          <div class="right">
            <el-switch
              v-model="pasteImageSwitch"
              @change="changePasteImageSwatch"
            />
          </div>
          <!-- <div class="left">
            <div class="title">
              直播推荐
            </div>
            <div class="intro">
              开启后，本场直播将被官方渠道收录
            </div>
          </div>
          <div class="right">
            <el-switch
              v-model="recommend"
              @change="changeCommend"
            />
          </div> -->
        </li>
        <li>
          <div class="left">
            <div class="title">
              主播副号
            </div>
            <div class="intro">
              直播时，主播副号能在主播端进行商品推送、抽奖等操作
            </div>
            <div
              v-if="viceAccountList.length > 0"
              class="vice"
            >
              <p
                v-for="(item,index) in viceAccountList"
                :key="index"
              >
                副号{{ index+1 }}: {{ item }}
                <el-button
                  style="marginLeft: 10px"
                  size="mini"
                  type="text"
                  @click="deleteVice(item)"
                >
                  删除
                </el-button>
              </p>
            </div>
          </div>
          <div class="right">
            <el-button
              type="text"
              size="mini"
              @click="addCountermark"
            >
              添加副号
            </el-button>
          </div>
        </li>
        <!-- <li>
                <div class="left">
                    <div class="title">直播观看人数</div>
                    <div class="intro">用于设置直播观看人数初始值</div>
                </div>
                <div class="right">
                    <el-button @click="liveCountVisible = true" type="text" size="mini">
                        编辑
                    </el-button>
                </div>
            </li> -->
      </ul>
    </div>
    <blessingBag />
    <el-dialog
      v-if="countermarkVisible"
      title="添加副号"
      :visible.sync="countermarkVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <div class="label">
        副号微信号：
      </div>
      <el-input
        v-model="countermark"
        placeholder="填写副号的微信号"
        style="width: 70%; marginLeft: 10px;"
      />
      <div class="warn">
        直播时，主播副号能在主播端进行推送商品，抽奖等操作
      </div>
      <span slot="footer">
        <el-button
          type="primary"
          plain
          size="small"
          @click="() => countermarkVisible = false"
        >取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSure"
        >确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑直播人数初始值"
      :visible.sync="liveCountVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-input-number
        v-model="liveCount"
        :min="0"
        :max="1000000"
      />
      <span slot="footer">
        <el-button
          type="primary"
          plain
          size="small"
          @click="() => liveCountVisible = false"
        >取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="saveLiveCount"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import blessingBag from './blessing-bag.vue';
import CutImgUp from '@/components/common/cutImgUp';
/**
 * 直播数据页面
 * 用途：
 */

export default {
  name: 'live-set',
  components: {
    blessingBag, CutImgUp,
  },
  props: {
    activityId: Number,
  },
  data() {
    return {
      shutUp: false,
      openRecording: false,
      recommend: false,
      countermarkVisible: false,
      countermark: '',
      viceAccountList: [],
      liveCountVisible: false,
      liveCount: 0,
      imageUrlList: [],
      pasteImageSwitch: false,
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    // 图片选择
    onSuccess(obj) {
      console.log(obj);
      // 判断是否开启状态
      if (this.pasteImageSwitch && !obj[0]) {
        this.$message({
          message: '开启状态无法删除图片，请重新上传或刷新',
          type: 'warning',
        });
        return;
      }
      this.$axios.post(this.$api.live.live_edit_patse_image, {
        liveId: this.activityId,
        pasteImageUrl: obj[0] || '',
        pasteImageSwitch: this.pasteImageSwitch ? 1 : 0,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('贴图已上传');
          this.getSetting();
        }
      });
    },
    // 获取直播的相关配置
    getSetting() {
      this.$axios(this.$api.live.live_get_setting, {
        params: {
          id: this.activityId,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.shutUp = res.data.shutUp == 1;
          this.openRecording = res.data.openRecording == 1;
          this.recommend = res.data.recommend == 1;
          this.viceAccountList = res.data.viceAccountList;
          if (res.data.pasteImageUrl) {
            this.imageUrlList = [];
            this.imageUrlList.push(res.data.pasteImageUrl);
          }
          this.pasteImageSwitch = res.data.pasteImageSwitch === 1;
        }
      });
    },
    // 打开添加副号弹框
    addCountermark() {
      this.countermarkVisible = true;
    },

    // 确认添加副号
    onSure() {
      this.$axios(this.$api.live.live_add_vice, {
        params: {
          id: this.activityId,
          viceaccount: this.countermark,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.getSetting();
          this.$message.success('添加副号成功');
          // this.countermark = ''
          this.countermarkVisible = false;
        }
      });
    },

    // 删除副账号
    deleteVice(viceaccount) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios(this.$api.live.live_delete_vice, {
          params: {
            id: this.activityId,
            viceaccount,
          },
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success('删除成功');
            this.getSetting();
          }
        }).catch((error) => {
          console.log(error);
        });
      });
    },

    // 改变禁言
    changeForbid(val) {
      this.$axios(this.$api.live.live_shut_up, {
        params: {
          liveid: this.activityId,
          state: val ? 1 : 0,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('修改禁言状态成功');
          this.getSetting();
        }
      }).catch((error) => {
        console.log(error);
      });
    },

    // 是否录屏
    changePlay(val) {
      console.log(val, 'val');
      this.$axios(this.$api.live.live_open_recording, {
        params: {
          id: this.activityId,
          state: val ? 1 : 0,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('修改录屏状态成功');
          this.getSetting();
        }
      });
    },
    // 贴图是否打开
    changePasteImageSwatch(val) {
      // 判断是否有图片
      if (this.imageUrlList.length === 0) {
        this.$message({
          message: '请先上传贴图！',
          type: 'warning',
        });
        return;
      }
      this.$axios.post(this.$api.live.live_edit_patse_image, {
        liveId: this.activityId,
        pasteImageSwitch: val ? 1 : 0,
      }).then((res) => {
        if (res.code === 0) {
          if (val) {
            this.$message.success('已打开');
          } else {
            this.$message.success('已关闭');
          }

          this.getSetting();
        }
      });
    },

    // 是否推荐
    changeCommend(val) {
      console.log(val, 'val');
      this.$axios(this.$api.live.live_is_recommend, {
        params: {
          id: this.activityId,
          state: val ? 1 : 0,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('修改推荐成功');
          this.getSetting();
        }
      });
    },
    // 直播人数
    saveLiveCount() {
      this.$confirm('此操作将修改直播观看人数初始值, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.liveCountVisible = false;
        // this.$axios(this.$api.live.live_is_recommend, {
        //     params: {
        //         id: this.activityId,
        //         state: val ? 1 : 0
        //     }
        // }).then(res=> {
        //     if(res.code === 0) {
        //         this.$message.success('修改成功')
        //         this.getSetting()
        //     }
        // })
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },

  },
};
</script>
<style lang="scss" scoped>
.live_set_content {
    ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background: #f2f2f2;
        padding: 10px 20px 0;
        li {
            width: 49%;
            background: #ffffff;
            padding: 30px 20px;
            margin-bottom: 10px;
            border-radius: 10px;
            display: inline-flex;
            justify-content: space-around;
            align-items: center;

            .left {
                width: 70%;
                .title {
                    font-weight: 700;
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                .intro {
                    color: #999;
                }
                .vice {
                    margin-top: 10px;
                }
            }
            .live_set_content_img {
              width: 70%;
              display: flex;
              .left {
                width: 40%;
              }
              .title {
                margin-right: 20px;
              }
            }
            .right {
                width: 30%;
            }

        }
    }

    .label {
        display: inline-block;
        width: 20%;
    }
    .warn {
        margin: 10px 0 0 22%;
        color: #999;
    }
}

</style>
