<template>
  <div class="live_manage_content">
    <search-panel
      flex
      show-btn
      @getList="getListChange"
    >
      <div>
        <el-input
          v-model.trim="goods_title"
          placeholder="请输入商品名称"
          size="mini"
          clearable
          style="width: 300px"
        />
      </div>
      <el-button
        slot="button-right"
        size="mini"
        type="primary"
        @click="addGoods('add')"
      >
        添加商品
      </el-button>
      <el-button
        slot="button-right"
        size="mini"
        type="primary"
        @click="showBatchModel = true"
      >
        批量添加
      </el-button>
    </search-panel>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%"
      element-loading-text="拼命加载中"
    >
      <el-table-column
        prop="id"
        label="序号"
        width="110"
      />
      <el-table-column
        prop="goodsId"
        label="商品ID"
        width="110"
      />
      <el-table-column
        label="商品信息"
        min-width="210"
      >
        <template slot-scope="{row}">
          <div class="goods_message">
            <div class="left">
              <img :src="row.image" />
            </div>
            <div class="right">
              <div class="title">
                {{ row.goodsName }}
              </div>
              <div>
                <el-tag
                  v-if="row.promType !== 0"
                  size="mini"
                  effect="dark"
                  type="danger"
                >
                  {{ ACTIVE_TYPE_MAP[row.promType] }}
                </el-tag>
              </div>
              <div class="price">
                <span v-if="row.promType === 0">
                  <span v-if="row.minPrice === row.maxPrice">￥{{ row.minPrice }}</span>
                  <span v-else>￥{{ row.minPrice }} - ￥{{ row.maxPrice }}</span>
                </span>
                <span v-else>￥{{ row.lowPromPrice }}</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="remark"
        label="商品备注信息"
        min-width="210"
      />
      <!-- <el-table-column
            label="状态"
            width="110"
          >
            <template slot-scope="scope">
               {{scope.row.isOnSale == 1 ? '已上架' : '已下架'}}
            </template>
          </el-table-column> -->
      <el-table-column
        prop="clickCount"
        label="点击次数"
        width="110"
      />
      <el-table-column
        width="130px"
      >
        <template slot="header">
          排序
          <span class="orange f12">大数在前</span>
        </template>
        <template slot-scope="{row}">
          <el-input-number
            v-model="row.goodsWeight"
            class="w100"
            :min="0"
            :max="999999"
            placeholder="排序"
            size="mini"
            :controls="false"
            :precision="0"
            @blur="setSort(row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="220"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="toOperate(scope.row, 'push')"
          >
            推送
          </el-button>
          <el-button
            type="danger"
            plain
            size="mini"
            @click="toOperate(scope.row, 'delete')"
          >
            删除
          </el-button>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="toOperate(scope.row, 'top')"
          >
            置顶
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination-panel
      :page="pagination"
      :load="loading"
      show-select
      @getList="getList"
    />

    <!-- 添加商品弹框 -->
    <el-dialog
      v-if="selectDialogVisible"
      title="可选择商品"
      :visible.sync="selectDialogVisible"
      :before-close="() => selectDialogVisible = false"
      width="1200px"
      :close-on-click-modal="false"
    >
      <div class="describe">
        <div class="search">
          <el-input
            v-model="goodsMsg"
            placeholder="请搜索商品名称"
            size="mini"
            style="width: 300px"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchList"
            />
          </el-input>
          <div class="select_goods">
            {{
              selectedList && selectedList.length >= 0 ? `已选择${selectedList.length}件商品，还可选择${200 - selectedList.length }件商品` : ''
            }}
            <el-button
              :disabled="selectedList && selectedList.length <= 0"
              style="marginLeft: 20px"
              type="primary"
              size="mini"
              @click="onSureAdd"
            >
              导入商品
            </el-button>
          </div>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="selectTableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        style="width: 100%"
        :row-key="getRowKey"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
          :reserve-selection="true"
        />
        <el-table-column
          label="商品信息"
          min-width="210"
        >
          <template slot-scope="scope">
            <div class="goods_message">
              <div class="left">
                <img :src="scope.row.coverImgUrl" />
              </div>
              <div class="right">
                <div class="title">
                  {{ scope.row.name }}
                </div>
                <div class="id">
                  {{ scope.row.minSellPrice }} - {{ scope.row.maxSellPrice }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination-panel
        :page="selectPagination"
        :load="selectLoading"
        show-select
        @getList="getModalList"
      />
    </el-dialog>

    <!-- 批量添加商品 -->
    <el-dialog
      title="批量添加商品"
      :visible.sync="showBatchModel"
      width="600px"
    >
      <div>
        <p style="line-height: 22px">
          请输入商品ID
        </p>
        <div>
          <el-input
            v-model.trim="bathGoodsId"
            style="width: 100%"
            type="textarea"
            :rows="8"
            placeholder="请输入商品ID，多个商品ID之间用英文逗号隔开"
            @input="bathGoodsId = bathGoodsId.replace(/，/g, ',').replace(/[^\d,]/g, '').replace(/(,)\1/g, ',').replace(/^,/, '')"
            @blur="bathGoodsId = Array.from(new Set(bathGoodsId.replace(/,$/, '').split(','))).join(',')"
          />
        </div>
        <div style="margin-top: 10px">
          请在上方输入商品ID，多个商品ID之间用英文逗号隔开
        </div>
        <div style="color: red; margin-top: 10px; line-height: 20px">
          {{ bathGoodsText }}
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          @click="batchAddGoods"
        >确认添加</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 活动报名列表页面
 * 用途：用于供应商参加活动报名
 */
import { ACTIVE_TYPE_MAP } from '../../config.js';

export default {
  name: 'goods-manage',
  props: {
    activityId: Number,
    liveId: Number,
  },
  data() {
    return {
      ACTIVE_TYPE_MAP,
      goods_title: '',
      selectDialogVisible: false,
      goodsMsg: '',
      selectTableData: [],
      selectLoading: false,
      selectPagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10,
      },
      selectedList: [], // 选择的商品
      tableData: [],
      loading: false,
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10,
      },

      showBatchModel: false, // 批量添加商品弹窗
      bathGoodsId: '', // 批量添加id
      bathGoodsText: '', // 提示

    };
  },
  mounted() {
    this.getList();
  },
  methods: {

    getListChange() {
      this.pagination.page = 1;
      this.getList();
    },

    getList(index) { // index为页面当前页数 searchTitle为搜索参数
      let _this = this;
      _this.loading = true;

      _this.$axios.post(
        _this.$api.live.live_goods_list,
        {
          currentPage: _this.pagination.page || index,
          pageSize: _this.pagination.page_count,
          id: _this.activityId,
          goodsName: _this.goods_title,
        },
      ).then((res) => {
        if (res.code === 0) {
          const {
            size, total, current, pages,
          } = res.data;
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total),
          };
          _this.tableData = res.data.records;
        } else {
          _this.tableData = [];
          _this.pagination.item_total = 0;
        }
        _this.loading = false;
      }).catch((error) => {
        console.log(error);
      });
    },

    searchList() {
      this.getModalList();
    },

    getModalList(index) {
      let _this = this;
      _this.selectLoading = true;
      _this.$axios.post(_this.$api.liveGoods.live_goods_select, {
        name: _this.goodsMsg,
        currentPage: _this.selectPagination.page || index,
        pageSize: _this.selectPagination.page_count,
      }).then((res) => {
        if (res.code === 0) {
          const {
            size, total, current, pages,
          } = res.data;
          _this.selectPagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total),
          };
          _this.selectTableData = res.data.records;
        } else {
          _this.selectTableData = [];
          _this.selectPagination.item_total = 0;
        }
        _this.selectLoading = false;
      }).catch((error) => {
        console.log(error);
      });
    },

    // 选择商品
    addGoods() {
      this.selectDialogVisible = true;
      this.getModalList();
    },

    handleSelectionChange(val) {
      this.selectedList = val;
    },
    getRowKey(row) {
      return row.id;
    },

    // 确认添加商品
    onSureAdd() {
      let ids = [];
      this.selectedList.map((item) => {
        ids.push({
          goodsId: item.id,
          goodsName: item.name,
          liveActivityId: this.activityId,
          liveId: this.liveId,
        });
      });
      this.$axios.post(this.$api.live.live_sure_add, ids).then((res) => {
        if (res.code === 0) {
          this.$message.success('导入成功');
          this.selectDialogVisible = false;
          this.getList();
        }
      }).catch((error) => {
        console.log(error);
      });
    },

    // 批量添加商品
    batchAddGoods() {
      if (!this.bathGoodsId) {
        this.$message.warning('请输入商品id');
        return;
      }
      this.$confirm('是否确认添加商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.live.batch_add_live_goods, {
          goodsIds: this.bathGoodsId,
          liveId: this.activityId,
        }).then((res) => {
          if (res.code === 0) {
            if (res.data === '操作成功') {
              this.$message.success(res.data);
              this.showBatchModel = false;
              this.bathGoodsId = '';
            } else {
              this.$message.warning(res.data);
              this.bathGoodsText = res.data;
            }
            this.getList();
          }
        });
      }).catch(() => {
      });
    },
    async toOperate({
      goodsId,
      id,
      promGoodsId,
      promGroupingId,
    }, type) {
      await this.$confirm('是否确认操作？');
      let url;
      const params = {
        liveid: this.activityId,
        goodsid: goodsId,
      };
      if (type === 'push') {
        url = this.$api.live.live_push_goods;
        params.promGoodsId = promGoodsId === 0 ? '' : promGoodsId;
        params.promGroupingId = promGroupingId === 0 ? '' : promGroupingId;
      } else if (type === 'delete') {
        url = this.$api.live.live_delete_goods;
        params.id = id;
        delete params.goodsid;
      } else if (type === 'top') {
        url = this.$api.live.live_top_goods;
        params.id = id;
        delete params.goodsid;
      }
      const { code } = await this.$axios.get(url, { params });
      if (code !== 0) return;
      this.$message.success('操作成功');
      this.getList();
    },
    async setSort(row) {
      const params = {
        id: row.id,
        goodsWeight: row.goodsWeight,
      };
      const { code } = await this.$axios.post(this.$api.live.updateLiveGoodsInfo, params);
      this.getList();
      if (code !== 0) return;
      this.$message.success('操作成功');
    },
  },
};
</script>
<style lang="scss" scoped>
.live_manage_content{
  .select_goods{
    margin: 10px;
    display: inline-block;
  }
}

</style>
