var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "good-modal",
      attrs: {
        title: "中奖名单",
        visible: _vm.value,
        width: "900px",
        "before-close": _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tbLoad,
                  expression: "tbLoad",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tbList,
                height: "450",
                border: "",
                size: "small",
                "header-cell-style": {
                  background: "#d9dde1",
                  color: "#0d0202",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", prop: "userName", label: "中奖名单" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "couponName", label: "奖品" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "createTime",
                  label: "中奖时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.dayFormat(scope.row.createTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.page.total,
              "page-size": _vm.page.pageSize,
              "current-page": _vm.page.page,
            },
            on: {
              "current-change": _vm.changePage,
              "size-change": _vm.changePageSize,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }