<template>
  <div class="live_data_content">
    <div>
      <!-- <div
        v-show="JSON.stringify(allData) !== '{}'"
        class="data_content"
      >
        <div class="view_data">
          <div class="title">
            观看数据
          </div>
          <div class="content">
            <span>观看人数</span> {{ allData.liveWatchPerSonNum }}
          </div>
          <div class="content">
            <span>观看次数</span> {{ allData.liveWatchNum }}
          </div>
        </div>
        <div class="view_data">
          <div class="title">
            商品数据
          </div>
          <div class="content">
            <span>累计推送次数</span> {{ allData.pushNum }}
          </div>
          <div class="content">
            <span>商品点击人数</span> {{ allData.goodsClickPerconNum }}
          </div>
          <div class="content">
            <span>商品点击次数</span> {{ allData.goodsClickNum }}
          </div>
        </div>
        <div class="view_data">
          <div class="title">
            互动数据
          </div>
          <div class="content">
            <span>点赞人数</span> {{ allData.liveLikePersonNum }}
          </div>
          <div class="content">
            <span>点赞次数</span> {{ allData.liveLikeNum }}
          </div>
          <div class="content">
            <span>评论人数</span> {{ allData.commentPersonNum }}
          </div>
          <div class="content">
            <span>评论次数</span> {{ allData.commentNum }}
          </div>
          <div class="content">
            <span>分享人数</span> {{ allData.sharePersonNum }}
          </div>
          <div class="content">
            <span>分享次数</span> {{ allData.shareNum }}
          </div>
        </div>
      </div> -->
      <div class="box-wrap">
        <el-card class="box-card">
          <div>活动交易总额（元）：{{ transactionValue }}</div>
        </el-card>
        <el-card class="box-card">
          <div>支付订单数（笔）：{{ subOrderNum }}</div>
        </el-card>
        <el-card class="box-card">
          <div>活动交易商品数：{{ goodsNum }}</div>
        </el-card>
      </div>
      <div>
        <!-- 折线图 -->
        <lineEchart
          :sale-chart-date="saleChartDate"
          :sale-chart-count="saleChartCount"
          :sale-chart-money="saleChartMoney"
        />
        <lineMoneyEchart
          :sale-chart-date="saleChartDate"
          :sale-chart-count="saleChartCount"
          :sale-chart-money="saleChartMoney"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 直播数据页面
 * 用途：
 */
import dayjs from 'dayjs';
import lineEchart from './lineEchart.vue';
import lineMoneyEchart from './lineMoneyEchart.vue';

export default {
  name: 'live-data',
  components: {
    lineEchart,
    lineMoneyEchart,
  },
  props: {
    activityId: Number,
  },
  data() {
    return {
      allData: {},
      goodsNum: 0,
      subOrderNum: 0,
      transactionValue: 0,
      saleChartDate: [],
      saleChartCount: [],
      saleChartMoney: [],
    };
  },
  mounted() {
    // this.getData()
    this.getLiveTransactionDataByTime();
    this.getLiveTransactionTotalData();
  },
  methods: {
    getData() {
      this.$axios(this.$api.live.live_data_statistics, {
        params: {
          liveid: this.activityId,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.allData = res.data;
        }
      });
    },
    getLiveTransactionDataByTime() {
      this.$axios(this.$api.live.liveTransactionDataByTime, {
        params: {
          liveid: this.activityId,
        },
      }).then((res) => {
        if (res.code === 0) {
          res.data.forEach((item) => {
            this.saleChartDate.push(dayjs(Number(item.timing)).format('MM-DD HH:mm:ss'));
            this.saleChartCount.push(item.transactionGoodsNum);
            this.saleChartMoney.push(item.transactionValue);
          });
        }
      });
    },
    getLiveTransactionTotalData() {
      this.$axios(this.$api.live.liveTransactionTotalData, {
        params: {
          liveid: this.activityId,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.goodsNum = res.data.goodsNum;
          this.subOrderNum = res.data.subOrderNum;
          this.transactionValue = res.data.transactionValue;
        }
      });
    },

  },
};
</script>
<style lang="scss" scoped>
.live_data_content {
    .data_content {
        display: flex;
        justify-content: space-between;
        .view_data {
            width: 30%;
            .title {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 20px;
            }
            .content {
                margin-top: 10px;
            }
            span {
                display: inline-block;
                width: 30%;
            }
        }

    }

}
.box-wrap{
  display: flex;
  padding: 20px 0;
}
.box-card{
  width: 300px;
  margin-right: 20px;
}

</style>
