<template>
  <!-- 直播控制台功能迭代优化 -->
  <div class="blessing-bag">
    <div class="title">
      福袋：
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column
        fixed
        prop="type"
        label="规则"
        width="150"
      >
        <template slot-scope="scope">
          <el-select
            v-if="scope.row.isEdit && !scope.row?.id"
            v-model="scope.row.type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <div v-else>
            {{ options.find((item)=> scope.row.type === item.value ).label }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="condition"
        label="条件"
        width="250"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.isEdit && !scope.row?.id">
            <div v-if="scope.row.type === 1">
              观看
              <el-input-number
                v-model="scope.row.duration"
                placeholder="请输入分钟"
                size="small"
                :controls="false"
                :min="1"
                :max="60"
              />
              min
            </div>
            <el-date-picker
              v-if="scope.row.type === 2"
              v-model="scope.row.luckyTime"
              type="datetime"
              placeholder="选择日期时间"
            />
            <div v-if="scope.row.type === 3">
              评论
              <el-input-number
                v-model="scope.row.times"
                placeholder="请输入次数"
                size="small"
                :controls="false"
                :min="1"
                :max="100"
              />
              次
            </div>
          </div>
          <div v-else>
            <div v-if="scope.row.type === 1">
              观看  {{ scope.row.duration }}  min
            </div>
            <div v-if="scope.row.type === 2">
              {{ dayjs(parseInt(scope.row.luckyTime)).format('YYYY/MM/DD HH:mm:ss') }}
            </div>
            <div v-if="scope.row.type === 3">
              评论  {{ scope.row.times }}  次
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="奖品"
      >
        <template slot-scope="scope">
          <div
            :class="closeAndEdit(scope.row)?'link':''"
            @click="choseCoupon(scope)"
          >
            {{ scope.row.name||'请选择' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="lotteryPrizeTotal"
        label="奖品数量"
        width="140"
      >
        <template slot-scope="scope">
          <el-input-number
            v-if="closeAndEdit(scope.row)"
            v-model="scope.row.lotteryPrizeTotal"
            size="small"
            :controls="false"
            :min="1"
          />
          <div v-if="!closeAndEdit(scope.row)">
            {{ scope.row.lotteryPrizeTotal }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="lotteryProbability"
        label="中奖概率"
        width="160"
      >
        <template slot-scope="scope">
          <div
            v-if="scope.row.isEdit "
            flex
          >
            <el-input-number
              v-model="scope.row.lotteryProbability"
              size="small"
              :controls="false"
              :min="1"
              :max="100"
            />
            %
          </div>
          <div v-if="!scope.row.isEdit">
            {{ scope.row.lotteryProbability }}%
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="lotteryPrizeStore"
        label="剩余奖品"
        width="140"
      />
      <el-table-column
        label="操作"
        width="180"
      >
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.isEdit"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="scope.row.isEdit"
            type="text"
            size="small"
            @click="save(scope.row)"
          >
            保存
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="deletes(scope.row,scope.$index)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="clickBlessing(scope.row)"
          >
            数据
          </el-button>
          <el-switch
            v-model="scope.row.state"
            class="table-switch"
            @change="change(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-button
      type="text"
      size="small"
      class="table-button"
      @click="addData"
    >
      <i class="el-icon-plus el-icon--left"></i>
      添加数据
    </el-button>
    <couponModal
      v-model="showCouponModal"
      @select="selectCoupon"
    />
    <blessingData
      v-model="showBlessing"
      :lucky-id="luckyId"
    />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue';
import dayjs from 'dayjs';
import couponModal from './coupon-modal.vue';
import blessingData from './blessing-data.vue';
// import { dateFormat } from '@/utils/utils';
// import CutImgUp from '@/components/common/cutImgUp';

const app = getCurrentInstance().proxy;
const showCouponModal = ref(false);
const showBlessing = ref(false);
const options = [
  {
    label: '观看直播',
    value: 1,
  },
  {
    label: '整点福利',
    value: 2,
  },
  {
    label: '参与评论',
    value: 3,
  },
];
const tableData = ref([]);
const currentIndex = ref('');
const luckyId = ref('');

const closeAndEdit = (row) => {
  return !row.state && row.isEdit;
};
const handleClick = (row) => {
  row.isEdit = true;
  console.log(row);
};
const save = (row) => {
  console.log(row);
  if (!row.type) {
    app.$message({
      message: '请选择规则！',
      type: 'warning',
    });
    return;
  }
  if ((row.type === 1 && !row.duration) || (row.type === 2 && !row.luckyTime) || (row.type === 3 && !row.times)) {
    app.$message({
      message: '请填写条件！',
      type: 'warning',
    });
    return;
  }
  if (!row?.lotteryPrizeId) {
    app.$message({
      message: '请选择优惠券！',
      type: 'warning',
    });
    return;
  }
  if (!row?.lotteryPrizeTotal) {
    app.$message({
      message: '请填写奖品数量！',
      type: 'warning',
    });
    return;
  }
  if (!row?.lotteryProbability) {
    app.$message({
      message: '请填写中奖概率！',
      type: 'warning',
    });
    return;
  }
  if (row.id) {
    let parm = {
      id: row.id,
      status: row.state ? 1 : 0,
    };
    if (row.state) {
      parm.lotteryProbability = row?.lotteryProbability; // 中奖率
    } else {
      parm.lotteryPrizeId = row?.lotteryPrizeId;
      parm.lotteryPrizeType = row?.lotteryPrizeType;
      parm.lotteryPrizeStore = row?.lotteryPrizeStore;
      parm.lotteryPrizeTotal = row?.lotteryPrizeTotal;
      parm.lotteryProbability = row?.lotteryProbability; // 中奖率
    }
    app.$axios.post(app.$api.live.lucky_bag_edit, parm).then((res) => {
      if (res.code !== 0) {
        return;
      }
      app.$message({
        message: '编辑成功',
        type: 'success',
      });
      getList();
    });
    return;
  }
  let duplicateData = tableData.value.find((item) => {
    if (item.type === row.type && item?.id) {
      if (item.type === 1) {
        return item.duration === row.duration;
      }
      if (item.type === 2) {
        return item.luckyTime === Date.parse(row.luckyTime);
      }
      if (item.type === 3) {
        return item.times === row.times;
      }
    }
  });
  console.log(duplicateData);
  if (duplicateData) {
    app.$message({
      message: '不能添加规则条件相同的福袋！',
      type: 'warning',
    });
    return;
  }
  let data = {
    type: row?.type,
    duration: row?.type === 1 ? row?.duration : '',
    times: row?.type === 3 ? row?.times : '',
    name: row?.name,
    lotteryPrizeId: row?.lotteryPrizeId,
    lotteryPrizeType: row?.lotteryPrizeType,
    lotteryPrizeStore: row?.lotteryPrizeStore,
    lotteryPrizeTotal: row?.lotteryPrizeTotal,
    lotteryProbability: row?.lotteryProbability,
    liveActivityId: app.$route.query.id,
    luckyTime: row?.type === 2 ? Date.parse(row.luckyTime) : '',
    status: row.state ? 1 : 0,
  };
  app.$axios.post(app.$api.live.lucky_bag_add, data).then((res) => {
    if (res.code !== 0) {
      return;
    }
    app.$message({
      message: '保存成功',
      type: 'success',
    });
    getList();
  });
};

const clickBlessing = (e) => {
  showBlessing.value = true;
  luckyId.value = e.id;
};
const change = (e) => {
  if (e.isEdit) {
    app.$message({
      message: '请先保存再开启规则！',
      type: 'warning',
    });
    e.state = !e.state;
    return;
  }
  let data = {
    id: e?.id,
    status: e?.state ? 1 : 0,
  };
  app.$axios.post(app.$api.live.lucky_bag_updateStatus, data).then((res) => {
    if (res.code !== 0) {
      return;
    }
    app.$message({
      message: data.status === 1 ? '福袋开启成功' : '福袋关闭成功',
      type: 'success',
    });
    getList();
  });
};
const deletes = (e, index) => {
  app.$confirm('确认要删除此福袋吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(() => {
    if (!e.id) {
      tableData.value.splice(index, 1);
      return;
    }
    app.$axios.get(app.$api.live.lucky_bag_delete, {
      params: {
        id: e.id,
      },
    }).then((res) => {
      if (res.code !== 0) {
        return;
      }
      getList();
    });
  }).catch(() => {});
};
const addData = () => {
  let data = {
    type: '',
    condition: '',
    duration: '',
    luckyTime: new Date(),
    times: '',
    name: '',
    lotteryPrizeStore: '',
    lotteryPrizeTotal: '',
    lotteryProbability: 0,
    isEdit: true,
    state: false,
  };
  tableData.value.push(data);
};
// 获取福袋列表
const getList = () => {
  app.$axios.post(app.$api.live.lucky_bag_page, {
    liveActivityId: app.$route.query.id,
    currentPage: 1,
    pageSize: 999,
  }).then((res) => {
    if (res.code !== 0) {
      return;
    }
    console.log(res, 'res');
    tableData.value = res.data.records.map((item) => {
      return {
        ...item,
        isEdit: false,
        state: item.status === 1,
      };
    });
    console.log(tableData.value);
  });
};

// 选择优惠券
const selectCoupon = (coupon) => {
  console.log(coupon);
  tableData.value[currentIndex.value].lotteryPrizeId = coupon.couponId;
  tableData.value[currentIndex.value].name = coupon.couponName;
  tableData.value[currentIndex.value].lotteryPrizeType = coupon.couponType;
};
const choseCoupon = (row) => {
  if (!row.row.state && row.row.isEdit) {
    showCouponModal.value = true;
    currentIndex.value = row.$index;
  }
};
onMounted(() => {
  getList();
});

</script>

<style lang="scss">
.blessing-bag {
  margin: 0 20px 20px 20px;
  padding: 0 20px 20px 20px;

  .el-input-number{
    width: 110px;
  }
  .link{
    text-decoration: underline;
    color: #1890ff;
    cursor: pointer;
  }
  .category_title_img {
    display: flex;
    padding-bottom: 20px;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .table-switch{
    margin-left: 10px;
  }
  .table-button{
    width: 100%;
    border: 1px solid #dfe6ec;
    border-top: none;
    height: 48px;
  }
}
</style>
