var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "live_set_content" },
    [
      _c("div", [
        _c("ul", [
          _c("li", [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c("el-switch", {
                  on: { change: _vm.changeForbid },
                  model: {
                    value: _vm.shutUp,
                    callback: function ($$v) {
                      _vm.shutUp = $$v
                    },
                    expression: "shutUp",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("li", [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c("el-switch", {
                  on: { change: _vm.changePlay },
                  model: {
                    value: _vm.openRecording,
                    callback: function ($$v) {
                      _vm.openRecording = $$v
                    },
                    expression: "openRecording",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("li", [
            _c(
              "div",
              { staticClass: "live_set_content_img" },
              [
                _vm._m(2),
                _c("CutImgUp", {
                  attrs: {
                    url: _vm.imageUrlList,
                    "auto-crop-width": 750,
                    "auto-crop-height": 750,
                    "max-num": 1,
                  },
                  on: { change: _vm.onSuccess },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c("el-switch", {
                  on: { change: _vm.changePasteImageSwatch },
                  model: {
                    value: _vm.pasteImageSwitch,
                    callback: function ($$v) {
                      _vm.pasteImageSwitch = $$v
                    },
                    expression: "pasteImageSwitch",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "title" }, [_vm._v(" 主播副号 ")]),
              _c("div", { staticClass: "intro" }, [
                _vm._v(" 直播时，主播副号能在主播端进行商品推送、抽奖等操作 "),
              ]),
              _vm.viceAccountList.length > 0
                ? _c(
                    "div",
                    { staticClass: "vice" },
                    _vm._l(_vm.viceAccountList, function (item, index) {
                      return _c(
                        "p",
                        { key: index },
                        [
                          _vm._v(
                            " 副号" +
                              _vm._s(index + 1) +
                              ": " +
                              _vm._s(item) +
                              " "
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { marginLeft: "10px" },
                              attrs: { size: "mini", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteVice(item)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "mini" },
                    on: { click: _vm.addCountermark },
                  },
                  [_vm._v(" 添加副号 ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("blessingBag"),
      _vm.countermarkVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "添加副号",
                visible: _vm.countermarkVisible,
                "close-on-click-modal": false,
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.countermarkVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "label" }, [_vm._v(" 副号微信号： ")]),
              _c("el-input", {
                staticStyle: { width: "70%", marginLeft: "10px" },
                attrs: { placeholder: "填写副号的微信号" },
                model: {
                  value: _vm.countermark,
                  callback: function ($$v) {
                    _vm.countermark = $$v
                  },
                  expression: "countermark",
                },
              }),
              _c("div", { staticClass: "warn" }, [
                _vm._v(" 直播时，主播副号能在主播端进行推送商品，抽奖等操作 "),
              ]),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "", size: "small" },
                      on: { click: () => (_vm.countermarkVisible = false) },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onSure },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑直播人数初始值",
            visible: _vm.liveCountVisible,
            "close-on-click-modal": false,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.liveCountVisible = $event
            },
          },
        },
        [
          _c("el-input-number", {
            attrs: { min: 0, max: 1000000 },
            model: {
              value: _vm.liveCount,
              callback: function ($$v) {
                _vm.liveCount = $$v
              },
              expression: "liveCount",
            },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "small" },
                  on: { click: () => (_vm.liveCountVisible = false) },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveLiveCount },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 直播间禁言 ")]),
      _c("div", { staticClass: "intro" }, [
        _vm._v(" 开启后，所有观众无法在直播间发表评论 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 直播回放 ")]),
      _c("div", { staticClass: "intro" }, [
        _vm._v(" 开启后，观众可在直播结束页面查看本场直播回放 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 直播间贴图 ")]),
      _c("div", { staticClass: "intro" }, [_vm._v(" 宽度固定135pt ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }