var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "live_manage_content" },
    [
      _c("div", { staticClass: "app-container outer" }, [
        JSON.stringify(_vm.liveControlData) != "{}"
          ? _c("div", [
              _c("div", { staticClass: "live_manage_title" }, [
                _vm._v(" 直播管理 "),
              ]),
              _c("div", { staticClass: "live_content" }, [
                _c(
                  "div",
                  { staticClass: "live_img" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: _vm.liveControlData.coverImageId,
                        fit: "contain",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "live_msg" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(" " + _vm._s(_vm.liveControlData.title) + " "),
                  ]),
                  _c("div", { staticClass: "time" }, [
                    _vm._v(
                      " 开播时间：" +
                        _vm._s(
                          _vm.dayFormat(_vm.liveControlData.liveStartTime)
                        ) +
                        " - " +
                        _vm._s(_vm.dayFormat(_vm.liveControlData.liveEndTime)) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "anchor" }, [
                    _vm._v(
                      " 主播：" + _vm._s(_vm.liveControlData.actorName) + " "
                    ),
                  ]),
                  _c("div", { staticClass: "anchor_number" }, [
                    _vm._v(
                      " 主播账号：" + _vm._s(_vm.liveControlData.actorId) + " "
                    ),
                  ]),
                ]),
              ]),
              _vm.liveControlData.remainingTime > 0
                ? _c(
                    "div",
                    { staticClass: "live_prediction" },
                    [
                      _vm._v(" 直播预告: "),
                      _c("CountDown", {
                        attrs: {
                          "remaining-time": _vm.liveControlData.remainingTime,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "live_tabs" },
          [
            _vm.activeName == "data"
              ? _c(
                  "div",
                  { staticClass: "more", on: { click: _vm.showMoreData } },
                  [_c("i", { staticClass: "el-icon-more" })]
                )
              : _vm._e(),
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "直播商品", name: "goods" } },
                  [
                    _vm.activeName == "goods"
                      ? _c("GoodsManage", {
                          attrs: {
                            "live-id": _vm.liveId,
                            "activity-id": _vm.id,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "优惠券", name: "coupon" } },
                  [
                    _vm.activeName == "coupon"
                      ? _c("Coupon", { attrs: { "activity-id": _vm.id } })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "直播数据", name: "data" } },
                  [
                    _vm.activeName == "data"
                      ? _c("LiveData", { attrs: { "activity-id": _vm.id } })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "设置", name: "set" } },
                  [
                    _vm.activeName == "set"
                      ? _c("LiveSet", { attrs: { "activity-id": _vm.id } })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "数据",
            visible: _vm.showMore,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showMore = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: JSON.stringify(_vm.allData) !== "{}",
                  expression: "JSON.stringify(allData) !== '{}'",
                },
              ],
              staticClass: "data_content",
            },
            [
              _c("div", { staticClass: "view_data" }, [
                _c("div", { staticClass: "title" }, [_vm._v(" 观看数据 ")]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("观看人数")]),
                  _vm._v(" " + _vm._s(_vm.allData.liveWatchPerSonNum) + " "),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("观看次数")]),
                  _vm._v(" " + _vm._s(_vm.allData.liveWatchNum) + " "),
                ]),
              ]),
              _c("div", { staticClass: "view_data" }, [
                _c("div", { staticClass: "title" }, [_vm._v(" 商品数据 ")]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("累计推送次数")]),
                  _vm._v(" " + _vm._s(_vm.allData.pushNum) + " "),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("商品点击人数")]),
                  _vm._v(" " + _vm._s(_vm.allData.goodsClickPerconNum) + " "),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("商品点击次数")]),
                  _vm._v(" " + _vm._s(_vm.allData.goodsClickNum) + " "),
                ]),
              ]),
              _c("div", { staticClass: "view_data" }, [
                _c("div", { staticClass: "title" }, [_vm._v(" 互动数据 ")]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("点赞人数")]),
                  _vm._v(" " + _vm._s(_vm.allData.liveLikePersonNum) + " "),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("点赞次数")]),
                  _vm._v(" " + _vm._s(_vm.allData.liveLikeNum) + " "),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("评论人数")]),
                  _vm._v(" " + _vm._s(_vm.allData.commentPersonNum) + " "),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("评论次数")]),
                  _vm._v(" " + _vm._s(_vm.allData.commentNum) + " "),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("分享人数")]),
                  _vm._v(" " + _vm._s(_vm.allData.sharePersonNum) + " "),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("span", [_vm._v("分享次数")]),
                  _vm._v(" " + _vm._s(_vm.allData.shareNum) + " "),
                ]),
              ]),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showMore = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showMore = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }