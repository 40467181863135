var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "downTime-wrapper" }, [
    _c("div", { staticClass: "time" }, [
      _c("span", { staticClass: "hour" }, [_vm._v(_vm._s(_vm.myDay))]),
      _vm._v(" 天 "),
      _c("div", { staticClass: "hour" }, [
        _c("span", [_vm._v(_vm._s(_vm.getHours1))]),
        _c("span", [_vm._v(_vm._s(_vm.getHours2))]),
      ]),
      _vm._v(" 时 "),
      _c("div", { staticClass: "minute" }, [
        _c("span", [_vm._v(_vm._s(_vm.getMinutes1))]),
        _c("span", [_vm._v(_vm._s(_vm.getMinutes2))]),
      ]),
      _vm._v(" 分 "),
      _c("div", { staticClass: "second" }, [
        _c("span", [_vm._v(_vm._s(_vm.getSeconds1))]),
        _c("span", [_vm._v(_vm._s(_vm.getSeconds2))]),
      ]),
      _vm._v(" 秒 "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }