<template>
  <!-- 选择优惠券 -->
  <el-dialog
    class="good-modal"
    title="选择优惠券"
    :visible="value"
    width="900px"
    :before-close="handleClose"
  >
    <div>
      <search-panel
        flex
        show-btn
        class="nav"
        @getList="searchClick"
      >
        <div>
          <p>券包ID:</p>
          <el-input
            v-model.trim="searchParams.couponPackageId"
            placeholder="请输入券包ID"
            size="mini"
            clearable
          />
        </div>
        <div>
          <p>优惠券ID:</p>
          <el-input
            v-model.trim="searchParams.couponName"
            placeholder="请输入优惠券ID"
            size="mini"
            clearable
          />
        </div>
        <div>
          <p>优惠券类型:</p>
          <el-select
            v-model="searchParams.couponSubType"
            placeholder="请选择优惠券类型"
            size="mini"
            @change="searchClick"
          >
            <el-option
              v-for="item in subCouponList"
              :key="item.typeId"
              :label="item.typeName"
              :value="item.typeId"
            />
          </el-select>
        </div>
      </search-panel>
      <div class="box">
        <el-table
          v-loading="tbLoad"
          :data="tbList"
          height="450"
          border
          style="width: 100%"
          size="small"
          :header-cell-style="{
            background: '#d9dde1',
            color: '#0d0202',
          }"
        >
          <el-table-column
            align="center"
            prop="id"
            label="优惠券类型"
          >
            <template slot-scope="{ row }">
              <span v-if="searchParams.couponSubType === 2">商家券</span>
              <span v-if="searchParams.couponSubType === 1">平台券</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponId"
            label="券（包）ID"
          >
            <template slot-scope="scope">
              {{ scope.row?.couponId || scope.row?.couponPackageId }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponName"
            label="优惠券名称"
          >
            <template slot-scope="scope">
              {{ scope.row?.couponName || scope.row?.couponPackageName }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            align="center"
            prop="discountsValue"
            label="满减规则"
          /> -->
          <el-table-column
            label="满减规则"
            min-width="110"
          >
            <template slot-scope="scope">
              <div v-if="scope.row?.couponSubType === 204 || !scope.row?.couponSubType">
                /
              </div>
              <div v-else>
                {{ discountsType && discountsType[scope.row.discountsType] }}
                {{ discountsConditionType && discountsConditionType[scope.row.discountsConditionType] }}
                {{ scope.row.discountsCondition }} 元可用
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            align="center"
            prop="stockQuantity"
            label="领取时间"
          /> -->
          <el-table-column
            label="领取时间"
            min-width="160"
          >
            <template slot-scope="scope">
              <div v-if="searchParams.couponSubType === 2">
                {{ dayFormat(scope.row.validStartTime) }} - {{ dayFormat(scope.row.validEndTime) }}
              </div>
              <div v-else>
                {{ dayFormat(scope.row.giveOutStartTime) }} - {{ dayFormat(scope.row.giveOutEndTime) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="id"
            label="操作"
          >
            <template slot-scope="{ row }">
              <el-button
                class="button-blue"
                :disabled="row.checked"
                type="text"
                @click="selectGood(row)"
              >
                {{ row.checked ? '已选择' : '选择' }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="page.total"
          :page-size="page.pageSize"
          :current-page="page.page"
          @current-change="changePage"
          @size-change="changePageSize"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { discountsType, discountsConditionType } from '../../config';

export default {
  props: {
    value: {
      default: false,
    },
    selectGoods: Object,
  },
  data() {
    return {
      tbList: [],
      tbLoad: false,
      // goodsName: '',
      searchParams: {
        couponName: '',
        couponPackageId: null,
        couponSubType: 2,
      },
      subCouponList: [{
        typeName: '商家券',
        typeId: 2,
      },
      {
        typeName: '平台券',
        typeId: 1,
      },
      ],
      page: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      discountsType,
      discountsConditionType,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getList();
      }
    },
  },
  mounted() {
  },
  methods: {
    selectGood(row) {
      // 判断平台券还是商家券
      if (this.searchParams.couponSubType === 1) {
        row.couponId = row.couponPackageId;
        row.couponName = row.couponPackageName;
        row.couponType = 1;
      }
      this.$emit('select', row);
      this.handleClose();
    },
    getList() {
      this.tbLoad = true;
      const pars = {
        currentPage: this.page.page,
        pageSize: this.page.pageSize,
        status: 1,
        activiteState: 1,
        storeId: JSON.parse(localStorage.getItem('userInfo')).defaultStoreId,
      };
      if (this.searchParams.couponPackageId) {
        pars.couponPackageId = this.searchParams.couponPackageId;
      }
      if (this.searchParams.couponName) {
        pars.couponName = this.searchParams.couponName;
      }
      pars.couponSubType = this.searchParams.couponSubType;
      console.log(this.searchParams);
      let url = null;
      // 判断平台券还是商家券
      if (this.searchParams.couponSubType === 2) {
        url = this.$api.coupon.template_list;
      } else {
        url = this.$api.coupon.search_coupon_package;
      }
      this.$axios.post(url, pars).then((res) => {
        if (res.code === 0) {
          this.tbList = res.data.records;
          this.page.total = Number(res.data.total);
        } else {
          this.tbList = [];
        }
        console.log(111111111);
        this.tbLoad = false;
      });
    },
    searchClick() {
      this.page.page = 1;
      this.getList();
    },
    handleClose(done) {
      this.$emit('input', false);
    },
    changePage(p) {
      this.page.page = p;
      this.getList();
    },
    changePageSize(s) {
      this.page.page = 1;
      this.page.pageSize = s;
      this.getList();
    },
    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
.good-modal {

  :deep(.el-dialog__body){
    padding-top: 10px;
  }
  .nav {
    margin-bottom: 15px;
  }
  .box {
    .good-img {
      width: 100px;
    }
  }
  .button-blue{
    border-color: transparent;
    background: transparent;
  }
}
</style>
