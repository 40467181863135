<template>
  <!-- 选择优惠券 -->
  <el-dialog
    class="good-modal"
    title="中奖名单"
    :visible="value"
    width="900px"
    :before-close="handleClose"
  >
    <div class="box">
      <el-table
        v-loading="tbLoad"
        :data="tbList"
        height="450"
        border
        style="width: 100%"
        size="small"
        :header-cell-style="{
          background: '#d9dde1',
          color: '#0d0202'
        }"
      >
        <el-table-column
          align="center"
          prop="userName"
          label="中奖名单"
        />
        <el-table-column
          align="center"
          prop="couponName"
          label="奖品"
        />
        <el-table-column
          align="center"
          prop="createTime"
          label="中奖时间"
        >
          <template slot-scope="scope">
            {{
              dayFormat(scope.row.createTime)
            }}
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.page"
        @current-change="changePage"
        @size-change="changePageSize"
      />
    </div>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    value: {
      default: false,
    },
    luckyId: {
      default: '',
    },
  },
  data() {
    return {
      tbList: [],
      tbLoad: false,
      page: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getList();
      }
    },
  },
  mounted() {},
  methods: {
    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss');
    },
    getList() {
      this.tbLoad = true;
      const pars = {
        currentPage: this.page.page,
        pageSize: this.page.pageSize,
        luckyPagId: this.luckyId,
      };
      this.$axios.post(this.$api.live.live_lottery_result, pars).then((res) => {
        if (res.code === 0) {
          this.tbList = res.data.records;
          console.log(this.tbList, 'this.tbList');
          this.page.total = Number(res.data.total);
        } else {
          this.tbList = [];
        }
        console.log(111111111);
        this.tbLoad = false;
      });
    },
    handleClose(done) {
      this.$emit('input', false);
    },
    changePage(p) {
      this.page.page = p;
      this.getList();
    },
    changePageSize(s) {
      this.page.page = 1;
      this.page.pageSize = s;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.good-modal {
  :deep(.el-dialog__body) {
    padding-top: 10px;
  }
  .nav {
    margin-bottom: 15px;
  }
  .box {
    .good-img {
      width: 100px;
    }
  }
}
</style>
