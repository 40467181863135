var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-card", { attrs: { shadow: "hover" } }, [
    _c("div", { staticClass: "sale-chart-box wuzq-content" }, [
      _c("div", { attrs: { id: "sale-money-echart" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }