<template>
  <el-card
    shadow="hover"
  >
    <div class="sale-chart-box wuzq-content">
      <div id="sale-money-echart" />
    </div>
  </el-card>
</template>

<script>
import echarts from 'echarts'
export default {
  name: 'SaleMoneyChart',
  props: {
    saleChartDate: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    saleChartCount: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    saleChartMoney: {
      type: Array,
      default: []
    }

  },
  data() {
    return {
      saleEchart: '',
      saleData: {}
    }
  },
  watch: {
    saleChartDate() {
      this.echartsInit()
    }

  },
  mounted() {
    this.saleEchart = echarts.init(document.getElementById('sale-money-echart'))
    this.echartsInit()
  },
  methods: {
    echartsInit() {
      this.saleData = {
        title: {
          text: '销售额'
        },
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          // feature: {
          //    saveAsImage: {}
          // }
        },
        legend: {
          data: ['活动交易商品数', '活动交易总额']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          lineStyle: {
            color: '#69B3FF',
            opacity: 0.5,
            width: 2
          },
          label: {
            show: true,
            backgroundColor: '#69B3FF'
          },
          handle: {
            show: true,
            color: '#69B3FF'
          },
          data: this.saleChartDate
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} 元'
          },
          axisTick: {
            inside: true
          },
          splitLine: {
            show: false
          }
        },
        series: [
          // {
          //   name: '活动交易商品数',
          //   type: 'line',
          //   smooth: true,
          //   itemStyle: {
          //     normal: {
          //       color: '#69B3FF'
          //     }
          //   },
          //   stack: '总量',
          //   areaStyle: {
          //     normal: {
          //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //         offset: 0,
          //         color: '#69B3FF'
          //       }, {
          //         offset: 1,
          //         color: '#ffffff'
          //       }])
          //     }
          //   },
          //   data: this.saleChartCount
          // },
          {
            name: '活动交易总额',
            type: 'line',
            smooth: true,
            itemStyle: {
              normal: {
                color: '#FFB6C1'
              }
            },
            stack: '总量',
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#FFB6C1'
                }, {
                  offset: 1,
                  color: '#ffffff'
                }])
              }
            },
            data: this.saleChartMoney
          }
        ]
      }
      if (this.saleData && typeof this.saleData === 'object') {
        this.saleEchart.setOption(this.saleData, true)
      }
    }
  }
}
</script>

<style scoped>
  #sale-money-echart{
    height:420px;
    overflow: hidden;
    margin: 0 auto;
  }
  .wuzq-content {
    padding: 0;
  }

</style>
