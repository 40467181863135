<template>
  <div class="live_coupon_content">
    <div>
      <search-panel
        flex
        show-btn
        @getList="getListChange"
      >
        <div>
          <el-input
            v-model.trim="coupon_title"
            placeholder="请输入优惠券名称"
            size="mini"
            clearable
            style="width: 300px"
          />
        </div>
        <!-- <el-button
              slot="button-right"
              size="mini"
              @click="addCoupon"
          >
              添加优惠券
          </el-button> -->
      </search-panel>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        element-loading-text="拼命加载中"
      >
        <el-table-column
          prop="couponId"
          label="优惠券ID"
          min-width="110"
        />
        <el-table-column
          prop="useRange"
          label="券类型"
          min-width="110"
        />
        <el-table-column
          prop="couponName"
          label="券名称"
          min-width="110"
        />
        <el-table-column
          prop="discountsValue"
          label="面额（元）"
          min-width="110"
        />
        <el-table-column
          label="使用条件"
          min-width="110"
        >
          <template slot-scope="scope">
            <div>{{ discountsType[scope.row.discountsType] }}{{ scope.row.discountsCondition }}{{ discountsConditionType[scope.row.discountsConditionType] }}{{ scope.row.discountsValue }}</div>
          </template>
        </el-table-column>

        <!-- <el-table-column
          prop="couponCount"
          label="优惠券总数量（张）"
          min-width="210"
        /> -->
        <el-table-column
          prop="couponIssueTotalCount"
          label="已发放数量（张）"
          min-width="110"
        />
        <el-table-column
          prop="couponReceiveCount"
          label="已领取（张）"
          min-width="110"
        />
        <el-table-column
          prop="userCount"
          label="已使用（张）"
          min-width="110"
        />
        <el-table-column
          label="有效时间"
          width="160"
        >
          <template slot-scope="scope">
            {{ dayjs(parseInt(scope.row.validStartTime)).format('YYYY-MM-DD HH:mm:ss') }} - {{ dayjs(parseInt(scope.row.validEndTime)).format('YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          width="110"
        >
          <template slot-scope="scope">
            {{ comState(scope.row.activiteState) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          min-width="200"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="toEdit(scope.row, 'show')"
            >
              查看
            </el-button>
            <el-divider direction="vertical" />
            <el-button
              :disabled="scope.row.activiteState !== 0"
              type="text"
              size="mini"
              @click="toEdit(scope.row, 'edit')"
            >
              编辑
            </el-button>
            <el-divider direction="vertical" />
            <el-button
              :disabled="scope.row.activiteState === 2 || scope.row.activiteState === 3"
              type="text"
              size="mini"
              @click="setCouponStatus(scope.row.couponId, 1)"
            >
              下架
            </el-button>
            <el-divider direction="vertical" />
            <el-button
              :disabled="scope.row.activiteState !== 3"
              type="text"
              size="mini"
              @click="setCouponStatus(scope.row.couponId, 0)"
            >
              上架
            </el-button>
            <el-divider direction="vertical" />
            <el-button
              :disabled="scope.row.activiteState === 2"
              type="text"
              size="mini"
              @click="toAddNum(scope.row)"
            >
              增发
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      v-if="couponVisible"
      :title="dialogName"
      :visible.sync="couponVisible"
      width="30%"
      :close-on-click-modal="false"
      center
    >
      <div v-if="dialogName == '添加优惠券'">
        <div class="label">
          请输入优惠券包ID：
        </div>
        <el-input
          v-model="couponId"
          style="width: 70%; marginRight: 10px"
        />
        <el-button
          type="primary"
          @click="onAdd"
        >
          确认添加
        </el-button>
        <div class="warn">
          添加优惠券多张的情况下，请用逗号隔开
        </div>
      </div>
      <div v-if="dialogName == '发券'">
        <div class="label">
          发放优惠券数量：
        </div>
        <el-input
          v-model="couponNum"
          style="width: 70%"
          min="1"
          :max="couponNum"
        /> 张
        <el-button
          style="marginLeft: 10px"
          type="primary"
          @click="onSend"
        >
          发放
        </el-button>
        <div class="warn">
          同一张优惠券可以多次发放
        </div>
      </div>
    </el-dialog>
    <!-- 增加优惠券发行量弹框 -->
    <el-dialog
      v-if="circulationVisible"
      title="增加优惠券发行量"
      :visible.sync="circulationVisible"
      width="30%"
    >
      <div class="margin">
        当前发行量:   {{ currentNum }} 张
      </div>
      <span>新增发行量:  </span>
      <el-input-number
        v-model="addNum"
        :min="0"
        size="mini"
        style="width: 200px"
      /> 张
      <div class="margin">
        增加后发行量:   {{ sum }} 张
      </div>
      <div class="warning margin">
        总发行量不可超过1000000
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="mini"
          @click="onSureAddCount"
        >确 定</el-button>
        <el-button
          size="mini"
          @click="circulationVisible = false"
        >取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 活动报名列表页面
 * 用途：用于供应商参加活动报名
 */

import dayjs from 'dayjs';
import { discountsType, discountsConditionType } from '../../config';

export default {
  name: 'coupon',
  props: {
    activityId: Number,
  },
  data() {
    return {
      coupon_title: '',
      discountsConditionType,
      discountsType,
      loading: false,
      tableData: [],
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10,
      },
      couponVisible: false,
      dialogName: '',
      couponId: '',
      couponNum: 100,
      sendCouponId: '',

      circulationVisible: false,
      currentNum: 0,
      addNum: 0,
    };
  },
  computed: {
    sum() {
      return parseFloat(this.currentNum) + this.addNum;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    dayjs,
    comState(val) {
      return val == 0 ? '未开始' : val == 1 ? '生效中' : val == 2 ? '已结束 (已失效)' : val == 3 ? '已结束 (已下架)' : '';
    },
    // 增发
    onSureAddCount() {
      if (this.sum > 1000000) {
        return this.$message.warning('总发行量超过1000000');
      }
      this.circulationVisible = false;
      this.$axios(this.$api.coupon.add_coupon_count, {
        params: {
          count: this.addNum,
          couponid: this.couponId,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('增加成功');
          this.currentNum = 0;
          this.addNum = 0;
          this.getList();
        }
      });
    },
    toAddNum(row) {
      this.circulationVisible = true;
      this.currentNum = row.couponCount;
      this.couponId = row.couponId;
    },
    // 上下架操作
    setCouponStatus(couponId, status) {
      let message = status === 0 ? '是否确认下架优惠券？' : '是否确认上架优惠券？';
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios.get(this.$api.coupon.operate_coupon, {
          params: {
            couponId,
            couponStatus: status,
          },
        })
          .then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功！',
                type: 'success',
              });
              this.getList();
            }
          });
      });
    },
    toEdit(row, type) {
      const { couponId, couponSubType } = row;
      this.$router.push({
        path: '/nb/marketingTools/coupon/addAndEdit',
        query: {
          couponSubType,
          id: couponId,
          storeId: this.storeId,
          operateType: type,
        },
      });
    },
    // 打开添加优惠券id 弹框
    addCoupon() {
      this.dialogName = '添加优惠券';
      this.couponVisible = true;
    },

    onAdd() {
      this.couponVisible = false;
      let coupons = this.couponId.split(',');
      this.$axios.post(this.$api.live.live_add_coupon, {
        coupons,
        liveid: this.activityId,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('添加成功');
          this.getList();
        }
      });
    },

    // 发券打开弹框
    toSendCoupon(id) {
      this.dialogName = '发券';
      this.couponVisible = true;
      this.sendCouponId = id;
    },

    onSend() {
      this.couponVisible = false;
      this.$axios(this.$api.live.live_issue_coupon, {
        params: {
          couponid: this.sendCouponId,
          issueCount: this.couponNum,
          liveid: this.activityId,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('发放成功');
          this.getList();
        }
      });
    },

    toDelete(id) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios(this.$api.live.live_delete_coupon, {
          params: {
            couponid: id,
            liveid: this.activityId,
          },
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success('删除成功');
            this.getList();
          }
        });
      });
    },

    getListChange() {
      // this.pagination.page = 1
      this.getList();
    },

    getList(index) { // index为页面当前页数 searchTitle为搜索参数
      let _this = this;
      _this.loading = true;
      _this.$axios({
        method: 'POST',
        url: _this.$api.live.live_coupon_list,
        data: {
          couponName: _this.coupon_title,
          name: _this.activityId,
          currentPage: _this.pagination.page || index,
          pageSize: _this.pagination.page_count,
          userToken: '',
        },
      }).then((res) => {
        if (res.code === 0) {
          const {
            size, total, current, pages,
          } = res.data;
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total),
          };
          _this.tableData = res.data.records;
        } else {
          _this.tableData = [];
          _this.pagination.item_total = 0;
        }
        _this.loading = false;
      }).catch((error) => {
        console.log(error);
      });
    },

  },
};
</script>
<style lang="scss" scoped>
.live_coupon_content {

  .label {
      margin-bottom: 10px;
  }
  .warn {
      color: #999;
      margin: 10px 0;
  }
}

</style>
